import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про Нас | Чистий Дім - Професійний клінінг сервіс
			</title>
			<meta name={"description"} content={"Дізнайтесь більше про клінінг сервіс Чистий Дім - нашу історію, місію та цінності. Ми завжди готові допомогти вам з прибиранням."} />
			<meta property={"og:title"} content={"Про Нас | Чистий Дім - Професійний клінінг сервіс"} />
			<meta property={"og:description"} content={"Дізнайтесь більше про клінінг сервіс Чистий Дім - нашу історію, місію та цінності. Ми завжди готові допомогти вам з прибиранням."} />
			<meta property={"og:image"} content={"https://clearvoicer.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://clearvoicer.com/img/3965825.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://clearvoicer.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://clearvoicer.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://clearvoicer.com/img/3965825.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://clearvoicer.com/img/3965825.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
		>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Про нас
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			Клінінг сервіс "Чистий Дім" був заснований у 2010 році з метою надання якісних послуг прибирання для жителів та бізнесів нашого міста. За цей час ми зарекомендували себе як надійного та професійного партнера у сфері клінінгу.
			</Text>
		</Section>
		<Section padding="120px 0 130px 0" background="--color-primary" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-2">
			<Text margin="0px 0px 20px 0px" font="--headline2" color="--light">
			Наша місія
			</Text>
			<Text
				margin="0px 100px 20px 0px"
				color="#fff"
				font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
				lg-margin="0px 0 80px 0px"
				md-margin="0px 0 50px 0px"
			>
				Наша місія - забезпечити чистоту та комфорт у кожному домі та офісі. Ми прагнемо надавати послуги найвищої якості, дбаючи про кожну деталь. Ми віримо, що чисте середовище сприяє здоров'ю та добробуту наших клієнтів.
			</Text>

<Image
		display="block"
		width="100%"
		object-fit="cover"
		height="100%"
		lg-width="100%"
		src="https://clearvoicer.com/img/7.jpg"
		max-height="700px"
		padding="0px 0px 20px"
	/>
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 20px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#fff">
						У "Чистий Дім" працює команда висококваліфікованих фахівців, які постійно підвищують свій професійний рівень. Наші клінери проходять регулярні тренінги та сертифікацію, щоб забезпечити найкращий сервіс для вас.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 20px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#fff">
						Основними цінностями нашої компанії є:
						<br/><br/>
						&nbsp;&nbsp;– Професіоналізм
						<br/><br/>
						&nbsp;&nbsp;– Відповідальність
						<br/><br/>
						&nbsp;&nbsp;– Чесність
						<br/><br/>
						&nbsp;&nbsp;– Орієнтованість на клієнта
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						
						<Text margin="0px 0px 20px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#fff">
						Ми пишаємось тим, що маємо багато задоволених клієнтів, які довіряють нам свій простір. Наші клієнти часто рекомендують нас своїм друзям та колегам, що є найкращим свідченням нашої роботи.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});